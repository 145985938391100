import React, {Component} from "react";
import KaisakuApi from "../../../controllers/kaisaku-api";
import {connect} from "react-redux";
import AndroidComm from "../../../controllers/utilities/unity-android-communication";
import View from "./login-form-view2";
import withTrans from "../../../i18n/withTrans";
import {Button, Header, Modal} from "semantic-ui-react";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import {environmentMode, getAdItem} from "../../lib/ad-manage-api-lib";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";

export class LoginForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            username: "",
            password: "",
            errorMsgs: [],
            loading: true,
            success: false            
        };
    }

    componentDidMount() {
        this.setState({
            loading: !!this.props.session
        });

        
    }

    onUsernameChange(e, data) {
        this.setState({username: data.value});
    }

    onPasswordChange(e, data) {
        this.setState({password: data.value});
    }

    async onSubmit() {
        this.setState({
            loading: true,
            success: false,
            errorMsgs: []
        });
        const res = await KaisakuApi.loginJ4(this.state.username, this.state.password, 0, "", this.props.platform);

        this.processResult(res);
    }

    

    async processResult(result) {
        console.log(result);
        if(result.success) {
            const { accessToken, userId } = result.session;
            // 登录成功，跳转到j4吧
            console.log("returnUrl");
            let returnUrl = this.props.returnUrl;
            if (!returnUrl.includes("http://") && (!returnUrl.includes("https://"))){
                returnUrl = returnUrl.replace("http:/","http://").replace("https:/","https://");
            }
            if (!returnUrl.includes("?")){
                returnUrl += "?accessToken=" + accessToken;
            }else{
                returnUrl += "&accessToken=" + accessToken;
            }
            window.location.href = returnUrl;
        } else {
            this.setState({
                loading: false,
                success: result.success,
                errorMsgs: result.errorMsgs.map(this.props.t)
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <View
                    loading={this.state.loading}
                    success={this.state.success}
                    errorMsgs={this.state.errorMsgs}
                    onUsernameChange={this.onUsernameChange.bind(this)}
                    onPasswordChange={this.onPasswordChange.bind(this)}
                    onSubmit={this.onSubmit.bind(this)}
                    returnUrl={this.props.returnUrl}
                    platform={this.props.platform}
                    q={this.props.q}
                />                
            </React.Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(withTrans(LoginForm));


