import React from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Message
} from "semantic-ui-react";
import {PasswordField, UsernameEmailField} from "../form-fields";
import {Link} from "gatsby";
import withTrans from "../../../i18n/withTrans";

import iconpwd from "../../../images/login/pwd.png";
import iconlogin from "../../../images/login/login.png";
import iconline from "../../../images/login/line.png";
import icon1 from "../../../images/login/quick.png";
import icon2 from "../../../images/login/sign.png";
import input_back from "../../../images/login/input_back.png";

function LoginFormView(props) {
    const {
        loading,
        success,
        errorMsgs,
        onUsernameChange,
        onPasswordChange,
        onSubmit,
        quickSignUp,
        gameId,
        q
    } = props;
    const restpwdurl = "/reset-password2/"+ q;
    return (
        <Container fluid>
            <Form size={"small"} loading={loading} success={success}
                  error={errorMsgs.length > 0}>
                <Card fluid style={{
                    boxShadow: '0 1px 1px 0 #fff, 0 0 0 1px #fff'
                }}>
                    {(errorMsgs.length > 0) && (<div style={{color:"red", paddingTop: "10px"}}>{props.t("COMMON.ERROR")}:{errorMsgs.join("\n").replace("Internal server error","系統繁忙，請重試")}</div>)}                    
                    <div>
                        <div style={{padding:"10px",height:"4em",
                        backgroundImage: `url(${input_back})` , backgroundSize:'100% 100%'
                        }}>
                        <UsernameEmailField 
                        style={{width:"90%", height: "90%" ,
                        marginTop: "-3px",
                        backgroundColor: "#2C2C2C", color:"#E4E4E4"}}  
                        onChange={onUsernameChange}/>
                        </div>
                        <div style={{padding:"10px",height:"4em",
                        backgroundImage: `url(${input_back})` , backgroundSize:'100% 100%'
                        }}>
                        <PasswordField
                        style={{width:"90%", height: "90%" ,
                        marginTop: "-3px",
                        backgroundColor: "#2C2C2C", color:"#E4E4E4"}}   
                        onChange={onPasswordChange}/>
                        </div>                        
                        <Button style={{
                            color:'#fff',
                            marginBottom: "5px",
                            height:"1.2em",
                            background: "transparent",
                            backgroundImage: `url(${iconpwd})` , backgroundSize:'100% 100%'}}
                            compact color={"grey"} size={"tiny"} floated={"center"} as={Link}
                                to={restpwdurl}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                        <Button
                            style={{
                                height:"3em",
                                background: "transparent",
                                backgroundImage: `url(${iconlogin})` , backgroundSize:'100% 100%'}}
                            fluid size='large' onClick={onSubmit}>
                            &nbsp;
                        </Button>
                    </div>
                </Card>                
            </Form>
        </Container>
    );
}

export default withTrans(LoginFormView);