import React, {Component} from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container, Form,
    Grid,
    GridColumn,
    GridRow,
    Image,
    Message
} from "semantic-ui-react";
import LoginForm from "../components/forms/login-form/login-form2";
import icon from "../images/jggxj4.svg";
import iconGg from "../images/gg-logo.png";
import {environmentMode, getMandatorySANDBOXMode} from "../components/lib/ad-manage-api-lib";
import {formatQuery} from "../controllers/utilities/string-utils";
import {Link} from "gatsby";
import {PasswordField, UsernameEmailField} from "../components/forms/form-fields";
import cookie from "react-cookies";
import "../styles/bg.css";

export default class extends Component {
    constructor(props, context) {
        super(props, context);
        let {returnUrl, platform} = formatQuery(this.props.location.search);        
        if (this.props.location.search){
            let list = this.props.location.search.split("returnUrl=");
            if (list.length >= 2){
                returnUrl = list[1].split("&")[0];
            }
        }
        this.state = {
            returnUrl:returnUrl,
            platform:platform
        }
    }


    render() {
        let iconJgg = icon;
                return (
                    <Grid className="loginDiv" textAlign='center' style={{
                        height: "100vh",
                        padding: "3vh",
                        margin: "0",
                        backgroundColor: "#FFF"
                    }} verticalAlign='middle'>
                        <Grid.Column style={{maxWidth: 450}}>
                            <Image style={{
                                marginBottom: "2vh",
                                height: "22vh"
                            }} centered src={iconJgg}/>
                            <LoginForm q={this.props.location.search} returnUrl={this.state.returnUrl} platform={this.state.platform}/>
                        </Grid.Column>
                    </Grid>
                );        
    }
};